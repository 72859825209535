<template>
  <div ref="map"></div>
</template>

<script>
let map
const geoc = new BMap.Geocoder()

export default {
  name: 'LocationSelection',
  props: {
    area: {
      type: String,
      default: '湖北省武汉市洪山区光谷总部国际'
    },
    location: String
  },
  data () {
    return {
      point: null
    }
  },
  watch: {
    area (e) {
      this.mapLoading(e)
    },
    location (e) {
      if (e) {
        const reg = new RegExp(/[0-9]+(\.[0-9]+)?,[0-9]+(\.[0-9]+)?/)
        if (reg.test(e)) {
          map.clearOverlays()
          const arr = e.split(',')
          this.point = new BMap.Point(Number(arr[0]), Number(arr[1]))
          geoc.getLocation(this.point, (rs) => {
            map.addOverlay(new BMap.Marker(rs.point))
            map.centerAndZoom(rs, 16)
          })
        }
      } else {
        this.point = null
        map.clearOverlays()
      }
    }
  },
  mounted () {
    map = new BMap.Map(this.$refs.map, { enableMapClick: false })
    map.disableDoubleClickZoom()
    map.disableDragging()
    if (this.location) {
      const arr = this.location.split(',')
      this.point = new BMap.Point(Number(arr[0]), Number(arr[1]))
      map.addOverlay(new BMap.Marker(this.point))
      map.centerAndZoom(this.point, 16)
    } else {
      this.mapLoading(this.area)
    }
    map.addEventListener('click', (e) => {
      // 弹出层遮罩
      const mask = document.createElement('div')
      mask.style.position = 'fixed'
      mask.style.top = '0'
      mask.style.left = '0'
      mask.style.display = 'flex'
      mask.style.flexDirection = 'column'
      mask.style.justifyContent = 'center'
      mask.style.alignItems = 'center'
      mask.style.width = '100vw'
      mask.style.height = '100vh'
      mask.style.backgroundColor = 'rgba(0, 0, 0, .3)'
      mask.style.zIndex = 9999
      // 大地图
      const div = document.createElement('div')
      div.style.width = 'calc(1280 / 1920 * 100vw)'
      div.style.height = 'calc(720 / 1080 * 100vh)'
      const bigMap = new BMap.Map(div, { enableMapClick: false })
      bigMap.enableScrollWheelZoom()
      bigMap.setDefaultCursor('crosshair')
      if (this.point) {
        setTimeout(() => {
          bigMap.centerAndZoom(this.point, 12)
          const marker = new BMap.Marker(this.point)
          const label = new BMap.Label(`${this.point.lng},${this.point.lat}`)
          const size = new BMap.Size(-50, -20)
          label.setOffset(size)
          marker.setLabel(label)
          bigMap.addOverlay(marker)
        }, 200)
      } else {
        geoc.getPoint(this.area, (point) => {
          if (point) {
            bigMap.centerAndZoom(point, 12)
          }
        })
      }
      bigMap.addEventListener('click', (e) => {
        bigMap.clearOverlays()
        const marker = new BMap.Marker(e.point)
        const label = new BMap.Label(`${e.point.lng},${e.point.lat}`)
        const size = new BMap.Size(-50, -20)
        label.setOffset(size)
        marker.setLabel(label)
        bigMap.addOverlay(marker)
        this.point = e.point
      })
      const footer = document.createElement('div')
      footer.style.marginTop = '20px'
      // 保存按钮
      const ok = document.createElement('button')
      ok.style.padding = 'calc(10 / 1080 * 100vh) calc(20 / 1920 * 100vw)'
      ok.style.background = '#4073b6'
      ok.style.color = '#fff'
      ok.innerText = '确 定'
      // 取消按钮
      const cancel = document.createElement('button')
      cancel.style.padding = 'calc(10 / 1080 * 100vh) calc(20 / 1920 * 100vw)'
      cancel.innerText = '取 消'
      footer.appendChild(cancel)
      footer.appendChild(ok)
      mask.appendChild(div)
      mask.appendChild(footer)
      document.body.appendChild(mask)
      ok.onclick = () => {
        // 清除监听，保存坐标，并关闭弹窗
        ok.removeEventListener('click', () => {})
        cancel.removeEventListener('click', () => {})
        bigMap.removeEventListener('click', () => {})
        geoc.getLocation(this.point, (rs) => {
          map.clearOverlays()
          map.centerAndZoom(this.point, 15)
          map.addOverlay(new BMap.Marker(this.point))
          this.$emit('locationSelection', rs)
        })
        mask.remove()
      }
      cancel.onclick = () => {
        // 清除监听，关闭弹窗
        ok.removeEventListener('click', () => {})
        cancel.removeEventListener('click', () => {})
        bigMap.removeEventListener('click', () => {})
        mask.remove()
      }
    })
  },
  destroyed () {
    map.removeEventListener('click', () => {})
  },
  methods: {
    /**
     * 地图加载
     * @param area - 地区
     */
    mapLoading (area) {
      geoc.getPoint(area, (point) => {
        if (point) {
          map.centerAndZoom(point, 15)
        }
      })
    }
  }
}
</script>
