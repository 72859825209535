<!-- 存储设备 -->
<template>
  <div class="main_box">
    <div class="nav_menu">
      <div class="menu_left">
        <el-input
          placeholder="请输入设备名称"
          class="search_input"
          v-model="value"
        ></el-input>
        <el-button type="primary" @click="handleSearch(0)">查询</el-button>
        <el-button type="info" @click="handleSearch(1)">重置</el-button>
      </div>
      <div class="menu_right">
        <el-button type="success" @click="handleStorage('add')"
          >新增设备</el-button
        >
      </div>
    </div>
    <div>
      <el-table
        :header-cell-style="{
          'background-color': '#4073b6',
          color: '#FFFFFF'
        }"
        :data="tableData"
        stripe
        style="width: 100%"
        v-loading="loading"
        element-loading-text="数据量过大，加载中..."
      >
        <el-table-column type="index" prop="sid" label="序号" width="95">
        </el-table-column>
        <el-table-column
          prop="storageName"
          label="设备名称"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column prop="locationType" label="定位类型">
          <template scope="scope">
            {{ scope.row.locationType == 1 ? "gps定位" : "wifi定位" }}
          </template>
        </el-table-column>

        <el-table-column
          prop="deviceAddress"
          label="设备地址码"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column
          prop="createTime"
          label="设备经纬度"
          :show-overflow-tooltip="true"
        >
          <template scope="scope">
            {{ scope.row.lng + "," + scope.row.lat }}
          </template>
        </el-table-column>
        <el-table-column width="250" label="操作">
          <template slot-scope="scope">
            <el-button class="edit" @click="handleStorage('edit', scope.row)"
              >编辑</el-button
            >
            <el-button class="delete" @click="handleDelete(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        :hide-on-single-page="true"
        layout="total, prev, pager, next, jumper"
        :total="pagination.total"
        @current-change="handleCurrent"
        :current-page="pagination.page"
      >
      </el-pagination>
    </div>
    <div class="addShow">
      <el-dialog
        class="border-r-8 role-dialog"
        :visible.sync="addShow"
        width="calc(388/1920*100vw)"
        :destroy-on-close="true"
      >
        <div slot="title" class="dialogTitle t-x font-s-18">{{ title }}</div>
        <div class="contentAdd" style="margin-bottom: 10px">
          <el-form :model="storageInfo" ref="ruleForm" :rules="ruleForm">
            <el-form-item
              prop="storageName"
              style="margin-top: calc(25 / 1080 * 100vh)"
            >
              <span class="label_r">设备名称</span>
              <el-input
                v-model="storageInfo.storageName"
                placeholder="请输入设备名称"
                maxlength="20"
              ></el-input>
            </el-form-item>
            <el-form-item
              prop="deviceAddress"
              style="margin-top: calc(30 / 1080 * 100vh)"
            >
              <span class="label_r">设备地址码</span>
              <el-input
                placeholder="请输入设备地址码"
                v-model="storageInfo.deviceAddress"
                :readonly="this.title == '存储设备管理-新增' ? false : true"
                maxlength="50"
              ></el-input>
            </el-form-item>
            <el-form-item style="margin-top: calc(30 / 1080 * 100vh)">
              <span class="label_r">经纬度选点</span>
              <LocationSelection
                :key="addShow"
                area="东营区"
                :location="storageInfo.coordinate"
                @locationSelection="locationSelection"
                style="
                  width: calc(244 / 1920 * 100vw);
                  height: calc(40 / 1080 * 100vh);
                "
              />
            </el-form-item>
            <el-form-item style="margin-top: calc(30 / 1080 * 100vh)">
              <span class="label_r">设备经纬度</span>
              <el-input
                placeholder="请输入经纬度"
                :readonly="true"
                v-model="storageInfo.coordinate"
              ></el-input>
            </el-form-item>
            <!-- 新增围栏 -->
            <el-form-item
              class="mgb"
              v-if="rail"
              style="margin-top: calc(30 / 1080 * 100vh)"
            >
              <span class="label_r">电子围栏</span>
              <div class="rail">
                <el-button
                  class="addRailBtn"
                  type="primary"
                  v-if="addRail"
                  @click="handleRail"
                  >添加围栏</el-button
                >
                <el-button class="addRailBtn" type="primary" v-else
                  >添加完成</el-button
                >
              </div>
            </el-form-item>
            <!-- 更改围栏 -->
            <el-form-item class="mgb" v-else>
              <span class="label_r">电子围栏</span>
              <div class="rail">
                <el-button
                  class="addRailBtn"
                  type="primary"
                  @click="handleUpdateRail"
                  >更改围栏</el-button
                >
              </div>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="handleShow" class="guanBi">关闭</el-button>
          <el-button
            @click="handleSave"
            type="primary"
            style="margin-left: calc(30 / 1920 * 100vw)"
            >保存</el-button
          >
        </span>
      </el-dialog>
    </div>
    <div class="mapShow">
      <el-dialog :visible.sync="mapShow">
        <div id="mapElectronics"></div>
        <div class="btn_box">
          <el-button type="primary" @click="goback">返回</el-button>
          <el-button type="primary" @click="addPreser" v-if="Preser"
            >保存</el-button
          ><el-button type="primary" @click="editPreser" v-else>保存</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {
  storeDelete,
  storeList,
  storageEdit,
  storageAdd
} from "../../../api/index";
import { typeList } from "../../AlarmLog";
import { guardFall } from "./validate";
import LocationSelection from "../../../components/LocationSelection/index.vue";
var isGuardFall = (rule, value, callback) => {
  if (!value) {
    callback(new Error("请输入设备地址码"));
  } else if (!guardFall(value)) {
    callback(new Error("请输入正确的设备地址码"));
  } else {
    callback();
  }
};
let arr = [];
export default {
  components: {
    LocationSelection
  },
  data() {
    return {
      poly: "",
      polyList: [],
      addRail: true,
      // 绘制完成返回的数组
      pathList: [],
      center: {
        lng: 114.3846,
        lat: 30.601451
      },
      addShow: false,
      pagination: {
        // 分页
        size: 10,
        page: 1,
        total: 0
      },
      value: "",
      tableData: [],
      loading: false,
      storageInfo: {
        coordinate: "",
        electronicFence: ""
      },
      title: "",
      stationList: [
        // 设备类型
        {
          label: "wifi定位",
          value: 0
        },
        {
          label: "gps定位",
          value: 1
        }
      ],
      rail: false,
      Preser: true, // 电子围栏页面保存
      ruleForm: {
        storageName: [
          { required: true, message: "请输入设备名", trigger: "blur" }
        ],
        deviceAddress: [
          { required: true, message: "请输入设备地址码", trigger: "blur" },
          {
            required: true,
            message: "请输入正确的设备地址码",
            trigger: "change",
            validator: isGuardFall
          }
        ],
        coordinate: [
          { required: true, message: "请选择设备坐标", trigger: "blur" }
        ]
      },
      regCoor: "",
      mapShow: false,
      styleOptions: {
        strokeColor: "#5E87DB", // 边线颜色
        fillColor: "#5E87DB", // 填充颜色。当参数为空时，圆形没有填充颜色
        strokeWeight: 2, // 边线宽度，以像素为单位
        strokeOpacity: 1, // 边线透明度，取值范围0-1
        fillOpacity: 0.2 // 填充透明度，取值范围0-1
      },
      labelOptions: {
        borderRadius: "2px",
        background: "#FFFBCC",
        border: "1px solid #E1E1E1",
        color: "#703A04",
        fontSize: "12px",
        letterSpacing: "0",
        padding: "5px"
      },
      map: null
    };
  },
  computed: {},
  watch: {},
  methods: {
    handleCurrent(pages) {
      this.pagination.page = pages;
      let { page, size } = this.pagination;
      this.getList();
    },
    handleSearch(type) {
      if (type) {
        this.value = "";
        this.pagination.page = 1;
      }
      this.getList();
    },
    handleVideo() {},
    // 删除
    handleDelete(row) {
      this.$confirm("此操作将永久删除该存储设备，确定删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          storeDelete({ id: row.id })
            .then(res => {
              if (res.code == 200) {
                this.$message({
                  message: res.message,
                  type: "success"
                });
              } else {
                this.$message({
                  message: res.message,
                  type: "error"
                });
              }
            })
            .finally(() => {
              if (this.pagination.total % 10 == 1) {
                this.pagination.page--;
              }
              this.getList();
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    },
    // 列表
    getList() {
      this.loading = true;
      let { page, size } = this.pagination;
      if (this.value != "") {
        storeList({
          page,
          size,
          storageName: this.value
        })
          .then(res => {
            if (res.code == 200) {
              this.tableData = res.data.list;
              console.log("this.tableData", this.tableData);
              this.pagination.total = res.data.total;
            }
          })
          .catch(err => {
            console.log(err);
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        storeList({
          page,
          size
        })
          .then(res => {
            if (res.code == 200) {
              this.tableData = res.data.list;
              console.log("this.tableData", this.tableData);
              this.pagination.total = res.data.total;
            }
          })
          .catch(err => {
            console.log(err);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    // 打开弹窗
    handleStorage(type, row) {
      // this.storageInfo = {};
      this.addShow = true;
      if (type == "edit") {
        this.title = "存储设备管理-编辑";
        this.addRail = true;
        // 延迟赋值
        console.log(this.storageInfo);
        console.log("row", row);
        this.storageInfo = JSON.parse(JSON.stringify(row));
        this.$set(this.storageInfo, "coordinate", row.lng + "," + row.lat);
        // this.storageInfo.coordinate = row.lng + "," + row.lat;
        this.rail = false;
      } else {
        this.$set(this.storageInfo, "storageName", "");
        this.$set(this.storageInfo, "deviceAddress", "");
        this.title = "存储设备管理-新增";
        this.addRail = true;
        this.rail = true;
        this.$set(this.storageInfo, "coordinate", "");
      }
    },
    handleShow() {
      this.addShow = false;
    },
    // 保存
    handleSave() {
      let { coordinate } = this.storageInfo,
        count = 0,
        arr = [];
      arr = this.storageInfo.coordinate.split(",");
      console.log(arr);
      if (this.title == "存储设备管理-编辑") {
        let {
          deviceAddress,
          id,
          storageName,
          electronicFence
        } = this.storageInfo;
        this.$refs["ruleForm"].validate(valid => {
          if (valid) {
            storageEdit({
              deviceAddress,
              id,
              longitude: arr[0],
              latitude: arr[1],
              storageName,
              electronicFence: electronicFence // 电子围栏
            })
              .then(res => {
                this.$message({
                  message: res.message,
                  type: "success"
                });
                this.addShow = false;
              })
              .catch(err => {
                this.$message({
                  message: err.message,
                  type: "error"
                });
              })
              .finally(() => {
                this.getList();
              });
          }
        });
      } else {
        let { deviceAddress, locationType, id, storageName } = this.storageInfo;
        this.$refs["ruleForm"].validate(valid => {
          if (valid) {
            if (this.storageInfo.electronicFence !== "") {
              storageAdd({
                deviceAddress,
                longitude: arr[0],
                latitude: arr[1],
                storageName,
                locationType,
                electronicFence: this.storageInfo.electronicFence // 电子围栏
              })
                .then(res => {
                  this.$message({
                    message: res.message,
                    type: "success"
                  });
                  this.addRail = true;
                  this.addShow = false;
                })
                .catch(err => {
                  this.$message({
                    message: err.message,
                    type: "error"
                  });
                })
                .finally(() => {
                  this.getList();
                });
            } else {
              this.$message.error("请添加电子围栏");
            }
          } else {
            return;
          }
        });
      }
    },
    // 电子围栏添加
    handleRail() {
      if (this.storageInfo.coordinate !== "") {
        this.mapShow = true;
        // this.addShow = false;
        setTimeout(() => {
          this.init();
        }, 100);
      } else {
        this.$message.error("请先输入设备经纬度");
      }
    },
    init() {
      var mapoint = this.storageInfo.coordinate.split(",");
      this.map = new BMap.Map("mapElectronics");
      this.map.centerAndZoom(new BMap.Point(mapoint[0], mapoint[1]), 15);
      this.map.enableScrollWheelZoom(true);
      // 实例化鼠标抓手
      var myDrawingManagerObject = new BMapLib.DrawingManager(this.map, {
        isOpen: false, //是否开启绘制模式
        drawingType: BMAP_DRAWING_MARKER,
        enableDrawingTool: true, // 是否显示工具栏
        enableCalculate: false,
        drawingToolOptions: {
          anchor: BMAP_ANCHOR_TOP_RIGHT,
          offset: new BMap.Size(5, 5), // 偏移值
          drawingTypes: [
            BMAP_DRAWING_MARKER,
            BMAP_DRAWING_CIRCLE,
            BMAP_DRAWING_POLYLINE,
            BMAP_DRAWING_POLYGON,
            BMAP_DRAWING_RECTANGLE
          ]
        },
        enableSorption: true, // 是否开启边界吸附功能
        sorptionDistance: 20, // 边界吸附距离
        enableGpc: true, // 是否开启延边裁剪功能
        enbaleLimit: true, // 是否开启超限提示
        circleOptions: this.styleOptions, // 圆的样式
        polylineOptions: this.styleOptions, // 线的样式
        polygonOptions: this.styleOptions, // 多边形的样式
        rectangleOptions: this.styleOptions, // 矩形的样式
        labelOptions: this.labelOptions, // label的样式
        polylineOptions: {
          strokeColor: "#333"
        }
      });
      let overlays = [];
      // var mlnglat = [];
      // var that = this;
      //添加鼠标绘制工具监听事件，用于获取绘制结果
      myDrawingManagerObject.addEventListener("overlaycomplete", e => {
        if (this.poly) {
          this.map.removeOverlay(this.poly);
        }
        // 请除上次绘制的电子围栏
        for (let i = 0; i < overlays.length; i++) {
          this.map.removeOverlay(overlays[i]);
          overlays.splice(i, 1);
        }
        overlays.push(e.overlay);
        this.pathList = e.overlay.getPath(); //Array<Point> 返回多边型的点数组
      });
    },
    // 返回弹框
    goback() {
      this.mapShow = false;
      this.addShow = true;
    },
    // 电子围栏保存
    addPreser() {
      this.mapShow = false;
      // this.addShow = true;
      this.addRail = false;
      this.storageInfo.electronicFence = JSON.stringify(this.pathList);
    },
    editPreser() {
      this.mapShow = false;
      this.addShow = true;
      this.addRail = false;
      this.storageInfo.electronicFence = JSON.stringify(this.pathList);
    },
    handleUpdateRail() {
      this.mapShow = true;
      this.addShow = false;
      this.Preser = false;
      setTimeout(() => {
        this.init();
        const polygonList = JSON.parse(this.storageInfo.electronicFence);
        const arr = [];
        polygonList.map(item => {
          arr.push(new BMap.Point(item.lng, item.lat));
        });
        // 添加多边形
        this.poly = new BMap.Polygon(arr, this.styleOptions);
        this.map.addOverlay(this.poly);
        this.poly.enableEditing();
        this.poly.addEventListener("lineupdate", e => {
          this.polyList = e.currentTarget.getPath();
        });
      }, 100);
    },
    UpdateInit() {},
    // 经纬度选点
    locationSelection(e) {
      if (e.point) {
        this.storageInfo.coordinate = `${e.point.lng},${e.point.lat}`;
      }
    }
  },
  created() {
    this.getList();
  },
  mounted() {}
};
</script>
<style lang="scss" scoped>
.addShow {
  ::v-deep .el-dialog {
    background: #4073b6;
    border-radius: 16px;
    width: calc(388 / 1920 * 100vw) !important;
    .el-dialog__header {
      background: #fff;
      margin-top: 4px;
      border-radius: 16px 16px 0 0;
    }
    .el-dialog__footer {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #fff;
      border-radius: 0 0 16px 16px;
      padding: calc(15 / 1080 * 100vh) 0;
      .el-button:nth-child(1):hover {
        background: #eaeaea;
        border: 1px #eaeaea solid;
        color: #313131;
      }
    }
    // 弹框主体
    .el-dialog__body {
      background: #fff;
      height: calc(395 / 1080 * 100vh);
      // 菜单选框
      .el-checkbox-group {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        label {
          width: 34% !important;
          height: calc(25 / 1080 * 100vh);
          margin-right: 0;
        }
      }
    }
    .el-input__inner {
      border: none;
      border-radius: 4px;
      background: #f3f4f8;
    }
  }
  .el-input,
  .el-textarea,
  .rail,
  .el-select {
    width: calc(244 / 1920 * 100vw);
  }
  ::v-deep .el-form-item__content {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    align-items: flex-start;
  }
  .label_r {
    margin-left: calc(22 / 1920 * 100vw) !important;
    text-align: left;
    color: #666;
    width: calc(90 / 1920 * 100vw);
    // margin-bottom: 10px;
  }
}
::v-deep .el-input.search_input {
  width: calc(175 / 1920 * 100vw);
}
::v-deep .mapShow {
  #mapElectronics {
    width: 100%;
    height: calc(810 / 1080 * 100vh);
  }
  .btn_box {
    position: absolute;
    top: calc(20 / 1080 * 100vh);
    left: calc(20 / 1920 * 100vw);
  }
  width: calc(1000 / 1920 * 100vw);
  .el-dialog {
    position: relative;
    top: calc(0 / 1080 * 100vh);
    left: calc(20 / 1920 * 100vw);
    width: calc(1570 / 1920 * 100vw);
  }
}

::v-deep .BMapLib_Drawing {
  .BMapLib_marker {
    display: none;
  }
  .BMapLib_polyline {
    display: none;
  }
  .BMapLib_polygon {
    display: none;
  }
  .BMapLib_circle {
    display: none;
  }
}
.addRailBtn {
  width: calc(85 / 1920 * 100vw);
}
</style>
